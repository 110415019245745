exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dlaczego-condo-js": () => import("./../../../src/pages/dlaczego-condo.js" /* webpackChunkName: "component---src-pages-dlaczego-condo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-katalog-grupy-five-seasons-js": () => import("./../../../src/pages/katalog-grupy-five-seasons.js" /* webpackChunkName: "component---src-pages-katalog-grupy-five-seasons-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasz-zespol-js": () => import("./../../../src/pages/nasz-zespol.js" /* webpackChunkName: "component---src-pages-nasz-zespol-js" */),
  "component---src-pages-nasze-inwestycje-dostepnosc-js": () => import("./../../../src/pages/nasze-inwestycje/dostepnosc.js" /* webpackChunkName: "component---src-pages-nasze-inwestycje-dostepnosc-js" */),
  "component---src-pages-nasze-inwestycje-js": () => import("./../../../src/pages/nasze-inwestycje.js" /* webpackChunkName: "component---src-pages-nasze-inwestycje-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-polityka-rodo-js": () => import("./../../../src/pages/polityka-rodo.js" /* webpackChunkName: "component---src-pages-polityka-rodo-js" */),
  "component---src-templates-investment-js": () => import("./../../../src/templates/investment.js" /* webpackChunkName: "component---src-templates-investment-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

