import React from "react"

const GlobalGuide = () => {
  return (
    <>
      {
        // <section className="global-guide">
        //   <div className="container-fluid">
        //     <div className="global-guide__wrapper">
        //       <h3>Poradnik Condo Inwestora</h3>
        //       <p>Zapisz sie na bezpłatku kurs inwestowania w condohotele.</p>
        //       <form>
        //         <div className="row align-items-center">
        //           <div className="col-sm-6">
        //             <div className="input-wrapper gold">
        //               <input type="text" placeholder="Imię" />
        //             </div>
        //           </div>
        //           <div className="col-sm-6">
        //             <div className="input-wrapper gold">
        //               <input type="email" placeholder="Adres e-mail" />
        //             </div>
        //           </div>
        //           <div className="col-md-8">
        //             <label className="copy-wrapper gold">
        //               <input type="checkbox" />
        //               <span>
        //                 Zgadzam się na otrzymywanie od Morris & Lloyd (rozwiń)
        //               </span>
        //             </label>
        //           </div>
        //           <div className="col-md-4 text-right">
        //             <Link
        //               to="/"
        //               className="btn-custom btn-custom--std btn-custom--arrow"
        //             >
        //               Wyślij
        //             </Link>
        //           </div>
        //         </div>
        //       </form>
        //     </div>
        //   </div>
        // </section>
      }
    </>
  )
}

export default GlobalGuide
