import React from "react"
import CountUp from "react-countup"

const ProgressBar = (props) => {
    const { bgcolor, completed, barLabel, completedText, completedTextColor } = props ;

    return (
        <div className="container-style">
            <div
              className="filler-style"
              style={{width: `${completed}%`, backgroundColor: `${bgcolor}`}}
            >
                <div class="label-style">
                  {`${barLabel}`}
                </div>
                <div
                  className="completion-style"
                  style={{color: `${completedTextColor}`}}
                >
                  <CountUp
                    end={`${completedText}`}
                    duration={`${completedText}`/8}
                    decimal=","
                    decimals={2}
                    suffix="%"
                    useEasing={1}
                  />
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;