import "./styles.scss"

import React from "react"

import ContactForm from "components/contactForm"

const Contact = () => {
  return (
    <section className="invest-contact">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="invest-contact__wrapper">
              <h3>Biuro sprzedaży</h3>

              <h4>Warszawa</h4>
              <address>
                ul. Marynarska 15
                <br />
                02-674 Warszawa
                <br />
                Godziny otwarcia:
                <br />
                pon - pt 9:00 - 16:00
              </address>

              <h3>Kontakt</h3>
              <a href="tel:+48 22 164 78 87">+48 22 164 78 87</a>
              <a href="mailto:biuro@5s.com.pl">biuro@5s.com.pl</a>
            </div>
          </div>

          <div className="col-lg-6 offset-lg-1 col-md-6">
            <h3>Formularz kontaktowy</h3>
            <ContactForm fullWidth />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
