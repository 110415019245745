/* eslint-disable jsx-a11y/no-onchange */

import "./styles.scss"

import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const Calc = ({ acf }) => {
  const [apartment, setApartment] = useState(0)
  const [ownerStay, setOwnerStay] = useState(false)
  const [open, isOpen] = useState(false)

  return (
    acf?.investmentApartments && (
      <section className="invest-calc" id="calc">
        <div className="invest-calc__wrapper">
          <div className="container-fluid">
            <h3>Kalkulator</h3>
            <div className="row">
              <div className="col-md-4">
                <h4>Lokal</h4>
                <form>
                  <div className="select-wrapper gold">
                    <select
                      onChange={e => setApartment(parseInt(e.target.value))}
                    >
                      {acf?.investmentApartments?.map((node, index) => (
                        <option value={index} key={index}>
                          {node.nazwaApartamentu}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label className="copy-wrapper gold bigger">
                    <input
                      type="checkbox"
                      checked={ownerStay}
                      onChange={e => setOwnerStay(!ownerStay)}
                      required
                    />
                    <span>Pobyt właścicielski</span>
                  </label>
                </form>
              </div>

              <div className="col-md-7 offset-md-1">
                <br />
                {acf?.investmentApartments?.map(
                  (node, index) =>
                    apartment === index && (
                      <div className="row" key={index}>
                        <div className="col-sm-4">
                          <p>Liczba pokoi:</p>
                          <strong>{node.liczbaPokoi}</strong>
                          <p>Powierzchnia lokalu:</p>
                          <strong>{node.powierzchniaPokoju}</strong>
                        </div>
                        <div className="col-sm-4">
                          <p>Cena netto:</p>
                          <strong>{node.cenaApartamentuNetto}</strong>
                          <p>Cena brutto:</p>
                          <strong>{node.cenaApartamentuBrutto}</strong>
                        </div>
                        <div className="col-sm-4">
                          <p>Roczny przychód netto:</p>
                          <strong>
                            {!ownerStay
                              ? node.rocznyPrzychodBezPobytuWlascicielskiego
                              : node.rocznyPrzychodZPobytemWlascicielskim}
                          </strong>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="invest-calc__data">
          <div className="container-fluid">
            <h3>Właściciel w każdym roku wybiera jedną z dwóch możliwości</h3>
            {acf?.investmentApartments?.map(
              (node, index) =>
                apartment === index && (
                  <div className="row align-items-center" key={index}>
                    <div className="col-lg-4">
                      <div className="invest-calc__data-item">
                        <p>
                          <img
                            src={
                              require("assets/images/invest/invest-icon.svg")
                                .default
                            }
                            alt=""
                          />
                          {node.rocznaStopaZwrotuBezPobytuWlascicielskiego} w
                          skali roku,
                        </p>
                        <strong>bez pobytu właścicielskiego</strong>
                      </div>

                      <div className="invest-calc__data-item">
                        <p>
                          <img
                            src={
                              require("assets/images/invest/invest-icon.svg")
                                .default
                            }
                            alt=""
                          />
                          {node.rocznaStopaZwrotuZPobytemWlascicielskim} w skali
                          roku,
                        </p>
                        <strong>14 dni pobytu właścicielskiego</strong>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      {node.rzutApartamentu && (
                        <>
                          <a
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                              isOpen(true)
                            }}
                            className="invest-calc__data-image"
                          >
                            <img
                              className="img-fluid"
                              src={
                                node.rzutApartamentu &&
                                node.rzutApartamentu.sourceUrl
                              }
                              alt=""
                            />
                          </a>
                          {open && (
                            <Lightbox
                              mainSrc={
                                node.rzutApartamentu &&
                                node.rzutApartamentu.sourceUrl
                              }
                              onCloseRequest={() => isOpen(false)}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-lg-4">
                      <div className="invest-calc__data-info">
                        <p>Wypłata miesięczna dla inwestora:</p>
                        <strong>
                          {!ownerStay
                            ? node.wyplataMiesiecznaDlaInwestoraBezPobytuWlascicielskiego
                            : node.wyplataMiesiecznaDlaInwestoraZPobytemWlascicielskim}
                        </strong>
                      </div>

                      <div className="invest-calc__data-info">
                        <p>Roczna stopa zwrotu:</p>
                        <strong>
                          {!ownerStay
                            ? node.rocznaStopaZwrotuBezPobytuWlascicielskiego
                            : node.rocznaStopaZwrotuZPobytemWlascicielskim}
                        </strong>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </section>
    )
  )
}

export default Calc
