import React from "react"
import ProgressBar from "../components/progressBar"

const ProgressChart = ({
  barOneWidth,
  barTwoWidth,
  barThreeWidth,
  barFourWidth,
  barFiveWidth,
  barSixWidth,
}) => {
  return (
    <div>
      <div className="progress-bar-container">
        <ProgressBar
          bgcolor="#1f1f2d"
          completed={barOneWidth}
          barLabel="Five Seasons umowa dzielonego przychodu"
          completedText={9.23}
          completedTextColor="#626268"
        />
      </div>
      <div className="progress-bar-container">
        <ProgressBar
          bgcolor="#1f1f2d"
          completed={barTwoWidth}
          barLabel="Five Seasons umowa ze stałym zyskiem"
          completedText={8}
          completedTextColor="#626268"
        />
      </div>
      <div className="progress-bar-container">
        <ProgressBar
          bgcolor="#4d4b67"
          completed={barThreeWidth}
          barLabel="Giełda"
          completedText={5.3}
          completedTextColor="#7b7a8c"
        />
      </div>
      <div className="progress-bar-container">
        <ProgressBar
          bgcolor="#4d4b67"
          completed={barFourWidth}
          barLabel="Mieszkanie"
          completedText={4.15}
          completedTextColor="#7b7a8c"
        />
      </div>
      <div className="progress-bar-container">
        <ProgressBar
          bgcolor="#4d4b67"
          completed={barFiveWidth}
          barLabel="Obligacje"
          completedText={3.2}
          completedTextColor="#7b7a8c"
        />
      </div>
      <div className="progress-bar-container">
        <ProgressBar
          bgcolor="#4d4b67"
          completed={barSixWidth}
          barLabel="Lokata"
          completedText={1.74}
          completedTextColor="#7b7a8c"
        />
      </div>
    </div>
  )
}

export default ProgressChart
