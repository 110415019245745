import "./styles.scss"

import React from "react"

const Chart = ({ acf }) => {
  return (
    acf?.chartImage && (
      <section className="invest-chart">
        <div className="container-fluid">
          <h3>Sukcesy w prowadzeniu inwestycji</h3>
          <img
            className="img-fluid"
            src={acf?.chartImage?.localFile?.publicURL}
            alt=""
          />
          <div dangerouslySetInnerHTML={{ __html: acf?.chartDescription }} />
        </div>
      </section>
    )
  )
}

export default Chart
