import "./styles.scss"

import React from "react"
import Slider from "react-slick"
import { Fade } from "react-awesome-reveal"

const Attracts = ({ acf }) => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    fade: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    (acf?.attractionsWinter || acf?.attractionsSummer) && (
      <section className="invest-attracts">
        <div className="container-fluid">
          <h3>Atrakcje</h3>
          {acf?.attractionsSummer && (
            <>
              <h4>Lato</h4>
              <Fade direction={"top"} triggerOnce>
                <Slider {...slider}>
                  {acf?.attractionsSummer?.map((item, index) => (
                    <div key={index}>
                      <div
                        className="invest-attracts__item"
                        style={{
                          backgroundImage: `url('${item?.image?.localFile?.publicURL}')`,
                        }}
                      >
                        <p>{item.header}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </Fade>
            </>
          )}
          {acf?.attractionsWinter && (
            <>
              <h4>Zima</h4>
              <Fade direction={"top"} triggerOnce delay={250}>
                <Slider {...slider}>
                  {acf?.attractionsWinter?.map((item, index) => (
                    <div key={index}>
                      <div
                        className="invest-attracts__item"
                        style={{
                          backgroundImage: `url('${item?.image?.localFile?.publicURL}')`,
                        }}
                      >
                        <p>{item.header}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </Fade>
            </>
          )}
        </div>
      </section>
    )
  )
}

export default Attracts
