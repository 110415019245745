import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

import GlobalGuide from "../components/globalGuide"

const RodoPolicy = ({ data }) => (
  <Layout
    seo={{
      title: "Polityka RODO",
    }}
  >
    <section className="subpage-top">
      <h1>Polityka RODO</h1>
    </section>

    <section className="default-content">
      <div
        className="container"
        dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
      />
    </section>

    <GlobalGuide />
  </Layout>
)

export default RodoPolicy

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxOTMx" }) {
      content
    }
  }
`
