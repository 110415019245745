import React from "react"

import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import ContactMap from "../components/contactMap"

const Kontakt = () => (
  <Layout
    seo={{
      title: "Kontakt",
    }}
  >
    <section className="subpage-top">
      <h1>Kontakt</h1>
    </section>

    <section className="contact-content">
      <div className="container-fluid">
        <div className="contact-content__info">
          <div className="row">
            <div className="col-lg-3">
              <address className="ico ico--address">
                ul. Marynarska 15, <br /> 02-674 Warszawa
              </address>
            </div>
            <div className="col-md-3">
              <a href="tel:+48 22 164 78 87" className="ico ico--phone">
                +48 22 164 78 87
              </a>
              <br />
              <a href="mailto:kontakt@5s.com.pl" className="ico ico--email">
                kontakt@5s.com.pl
              </a>
            </div>
            <div className="col-lg-6">
              <ContactForm textarea="true" />
            </div>
          </div>
        </div>
      </div>
      <div className="contact-content__map">
        <ContactMap />
      </div>
    </section>
  </Layout>
)

export default Kontakt
