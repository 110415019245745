import React from "react"

import ContactForm from "../components/contactForm"

const GlobalContact = ({ type, location }) => {
  return (
    <section className="global-guide">
      <div className="container-fluid">
        <div className="global-guide__wrapper">
          {type === "inBuild" ? (
            <h3>Zostaw dane do przesłania pełnej oferty inwestycji.</h3>
          ) : type === "buildDone" ? (
            <h3>
              Szukasz podobnej inwestycji? <br /> Zapraszamy do kontaktu.
            </h3>
          ) : (
            <h3>Kontakt</h3>
          )}
          <ContactForm gold fullWidth location={location} />
        </div>
      </div>
    </section>
  )
}

export default GlobalContact
