import React, { useState } from "react"

import ProgressChart from "../components/progressChart"
import { Waypoint } from "react-waypoint"

import Layout from "../components/layout"
import GlobalGuide from "../components/globalGuide"
import GlobalContact from "../components/globalContact"

const DlaczegoCondo = () => {
  const [barOneWidth, setBarOneWidth] = useState(0)
  const [barTwoWidth, setBarTwoWidth] = useState(0)
  const [barThreeWidth, setBarThreeWidth] = useState(0)
  const [barFourWidth, setBarFourWidth] = useState(0)
  const [barFiveWidth, setBarFiveWidth] = useState(0)
  const [barSixWidth, setBarSixWidth] = useState(0)

  const updateBars = () => {
    setTimeout(() => {
      setBarOneWidth(78)
    }, 160)
    setTimeout(() => {
      setBarTwoWidth(66.5)
    }, 320)
    setTimeout(() => {
      setBarThreeWidth(44.5)
    }, 480)
    setTimeout(() => {
      setBarFourWidth(34.5)
    }, 640)
    setTimeout(() => {
      setBarFiveWidth(27)
    }, 800)
    setTimeout(() => {
      setBarSixWidth(15)
    }, 960)
  }

  return (
    <Layout
      seo={{
        title: "Dlaczego Condo?",
      }}
    >
      <section className="subpage-top">
        <h1>Dlaczego Condo?</h1>
      </section>

      <section className="why-content">
        <div className="container-fluid">
          <h3>Zwrot z inwestycji w skali roku.</h3>
          <table className="why-chart-container">
            <div className="why-chart">
              <Waypoint onEnter={updateBars} />

              <ProgressChart
                barOneWidth={barOneWidth}
                barTwoWidth={barTwoWidth}
                barThreeWidth={barThreeWidth}
                barFourWidth={barFourWidth}
                barFiveWidth={barFiveWidth}
                barSixWidth={barSixWidth}
              />
            </div>
            <tr>
              <td></td>
              <td className="odd"></td>
              <td></td>
              <td className="odd"></td>
              <td></td>
              <td className="odd"></td>
              <td></td>
              <td className="odd"></td>
              <td></td>
              <td className="odd"></td>
              <td></td>
              <td className="odd"></td>
              <td></td>
              <td className="odd"></td>
            </tr>
            <tr className="desc">
              <td>0%</td>
              <td className="odd">1%</td>
              <td>2%</td>
              <td className="odd">3%</td>
              <td>4%</td>
              <td className="odd">5%</td>
              <td>6%</td>
              <td className="odd">7%</td>
              <td>8%</td>
              <td className="odd">9%</td>
              <td>10%</td>
              <td className="odd">11%</td>
              <td>12%</td>
              <td className="odd">13%</td>
              <td>14%</td>
            </tr>
          </table>

          <p style={{ marginTop: "18px" }}>Źródło:</p>
          <ol className="custom-col-2">
            <li>Uśredniony zysk w skali roku; Dział analiz Five Seasons.</li>
            <li>Zysk w skali roku; Umowa najmu Five Seasons.</li>
            <li>
              WIG 20 TOTAL RETURN [dostęp: 21 września 2018],{" "}
              <a
                href="https://stooq.pl/q/?s=wig20tr"
                target="_blank"
                without
                rel="noreferrer"
              >
                https://stooq.pl/q/?s=wig20tr
              </a>{" "}
              średnioroczny zysk dla inwestycji w trakcie ostatnich 20 lat.
            </li>
            <li>
              Ekspertka: aparthotele rentowną inwestycją na rynku mieszkaniowym
              [dostęp: 21 września 2018],{" "}
              <a
                href="http://biznes.onet.pl/wiadomosci/turystyka/
              ekspertka-aparthotele-rentowna-inwestycja-na-rynku-mieszkaniowym
              /3vpdg"
                target="_blank"
                without
                rel="noreferrer"
              >
                http://biznes.onet.pl/wiadomosci/turystyka/
                ekspertka-aparthotele-rentowna-inwestycja-na-rynku-mieszkaniowym
                /3vpdg
              </a>
            </li>
            <li>
              <a
                href="http://www.obligacjeskarbowe.pl"
                target="_blank"
                without
                rel="noreferrer"
              >
                http://www.obligacjeskarbowe.pl
              </a>{" "}
              [dostęp: 21 września 2018],
              <a
                href="http://www.obligacjeskarbowe.pl/oferta/"
                target="_blank"
                without
                rel="noreferrer"
              >
                http://www.obligacjeskarbowe.pl/oferta/
              </a>
            </li>
            <li>
              Potencjalna stopa zwrotu rocznej lokaty; NBP, lipiec 2018 [dostęp:
              21 września 2018],{" "}
              <a
                href="https://www.nbp.pl/home.aspx?f=/statystyka/pieniezna_i_bankowa/oprocentowanie.html"
                target="_blank"
                without
                rel="noreferrer"
              >
                https://www.nbp.pl/home.aspx?f=/statystyka/pieniezna_i_bankowa
                /oprocentowanie.html
              </a>
            </li>
          </ol>
          <div className="divider-60"></div>
          <h3>Dodatkowe korzyści finansowe</h3>
          <div className="custom-table">
            <div className="custom-table__header">
              <div className="row no-gutters">
                <div className="col-3">Condo Five Seasons</div>
                <div className="col-2">Lokata</div>
                <div className="col-2">Mieszkanie</div>
                <div className="col-3">Obligacje</div>
                <div className="col-2">Giełda</div>
              </div>
            </div>
            <div className="custom-table__row">
              <div className="row no-gutters">
                <div className="col-3 back">
                  Możliwość pobytu właścicielskiego; Wzrost wartości
                  nieruchomości: od 7% do 21%
                </div>
                <div className="col-2">Brak</div>
                <div className="col-2">
                  Potencjalny wzrost wartości nieruchomości
                </div>
                <div className="col-3">
                  Możliwość reinwestowania zysków z drobnym dyskontem wobec ceny
                  emisyjnej obligacji
                </div>
                <div className="col-2">Brak</div>
              </div>
            </div>
          </div>
          <p>
            Wykorzystując pobyt właścicielski, inwestor może zaoszczędzić na
            wyjeździe wakacyjnym, którego koszt dla czteroosobowej rodziny to
            średnio kilkanaście tysięcy złotych w obiekcie o standardzie 4 i 5
            gwiazdek1.
          </p>
          <p>
            Udokumentowany wzrost wartości apartamentu w Five Seasons wynosi od
            7% do 21%. Od 2016 roku sprzedaliśmy blisko 100 nieruchomości na
            rynku wtórnym2.
          </p>
          <p>
            Lokaty oraz granie na giełdzie nie oferują inwestorowi żadnych
            dodatkowych korzyści finansowych. Poza domyślnym zwrotem z
            inwestycji, nabywca nie zaoszczędzi, ani nie zarobi dodatkowych
            środków po zamknięciu lokaty czy sprzedaży akcji.
          </p>
          <p>
            Inwestycja w mieszkanie może przynieść dodatkowy zysk w postaci
            potencjalnego wzrostu wartości nieruchomości. Wpływ na to ma np.
            lokalizacja czy koniunktura na rynku. Przeciętny roczny wzrost
            wartości nieruchomości w Polsce w okresie od III kw. 2006 do II kw
            2018, wg. indeksu hedonicznego stworzonego przez NBP to 3,9%3. To o
            2 pkt. proc. ponad inflację.
          </p>
          <small>
            1. Cennik obiektów z grupy Five Seasons // Ceny z Booking.com dla
            top 10 w górach pod względem oceny
          </small>
          <small>2. Dział analiz Five Seasons</small>
          <small>
            3.{" "}
            <a
              href="https://www.nbp.pl"
              target="_blank"
              without
              rel="noreferrer"
            >
              https://www.nbp.pl
            </a>{" "}
            [dostęp: 21 września 2018] ,
            <a
              href="https://www.nbp.pl/home.aspx?f=/publikacje/rynek_nieruchomosci/index2.html"
              target="_blank"
              without
              rel="noreferrer"
            >
              https://www.nbp.pl/home.aspx?f=/publikacje/rynek_nieruchomosci/index2.html
            </a>
          </small>
          <div className="divider-60"></div>
          <h3>Czas potrzebny na bieżące zarządzanie</h3>
          <div className="custom-table">
            <div className="custom-table__header">
              <div className="row no-gutters">
                <div className="col-3">Condo Five Seasons</div>
                <div className="col-2">Lokata</div>
                <div className="col-2">Mieszkanie</div>
                <div className="col-3">Obligacje</div>
                <div className="col-2">Giełda</div>
              </div>
            </div>
            <div className="custom-table__row">
              <div className="row no-gutters">
                <div className="col-3 back">
                  &lsaquo;1 godzina <br /> miesięcznie
                </div>
                <div className="col-2">
                  ~ 1 godzina <br /> miesięcznie
                </div>
                <div className="col-2">
                  ~ 4 godziny <br /> miesięcznie
                </div>
                <div className="col-3 back">
                  ~ 1 godzina <br /> miesięcznie
                </div>
                <div className="col-2">
                  ~ 40 godzin <br /> miesięcznie
                </div>
              </div>
            </div>
          </div>
          <p>
            Apartamenty z oferty Five Seasons są bezobsługowe. Oznacza to, że
            nie muszą Państwo poświęcać cennego czasu – naprawami, konserwacją,
            a także zarządzeniem, marketingiem oraz innymi aspektami działania
            obiektu zajmie się doświadczony zespół fachowców. Inwestor po
            wybraniu pokoju hotelowego musi jedynie co miesiąc wystawiać
            fakturę, aby otrzymywać wynagrodzenie za wynajem.
          </p>
          <p>
            Zarządzanie lokatą również nie wymaga dużej ilości czasu, jednak
            inwestor musi śledzić rankingi opłacalności lokat i dokładnie
            przestudiować regulaminy, co kwartalnie może zająć kilka godzin.
          </p>
          <p>
            W przypadku mieszkania na wynajem, oprócz rozliczania czynszu,
            mediów, itp. inwestor musi być przygotowany na, nierzadko,
            czasochłonne problemy związane z eksploatacją i konserwacją
            nieruchomości, a niekiedy również uciążliwymi lokatorami.
          </p>
          <p>
            Nabywca obligacji skarbowych po zakupie papierów wartościowych nie
            musi doglądać swojej inwestycji. Jest ona zatem równie bezobsługowa,
            co w przypadku apartamentów Five Seasons.
          </p>
          <div className="divider-60"></div>
          <h3>Wyjście z inwestycji</h3>
          <div className="custom-table">
            <div className="custom-table__header">
              <div className="row no-gutters">
                <div className="col-3">Condo Five Seasons</div>
                <div className="col-2">Lokata</div>
                <div className="col-2">Mieszkanie</div>
                <div className="col-3">Obligacje</div>
                <div className="col-2">Giełda</div>
              </div>
            </div>
            <div className="custom-table__row">
              <div className="row no-gutters">
                <div className="col-3 back">&lsaquo; miesiąc</div>
                <div className="col-2">kilka minut</div>
                <div className="col-2">~ miesiąc</div>
                <div className="col-3 back">~ tydzień</div>
                <div className="col-2">~ 2 dni</div>
              </div>
            </div>
          </div>
          <p>
            Na rynku wtórnym znalezienie nabywcy na apartamenty Condo z oferty
            Five Seasons zajmuje mniej niż miesiąc, a od 2016 takich transakcji
            dokonaliśmy blisko 100. Niektóre lokale zostały sprzedane w kilka
            dni, a popyt na kolejne ciągle rośnie1.
          </p>
          <p>
            Lokata pozwala na najszybsze, spośród porównywanych opcji, wyjście z
            inwestycji. Jest to kilka minut potrzebnych na zlecenie zerwania
            lokaty i przelewu. Dodatkowo wymaga to jedynie łączności z
            internetem.
          </p>
          <p>
            O możliwości sprzedania mieszkania decyduje kilka czynników, m.in.:
            standard wykończenia, metraż czy cena. Atrakcyjna nieruchomość może
            znaleźć nabywcę w ciągu kilku dni, ale są też lokale, które z
            różnych względów nie zostaną sprzedane nawet przez kilka miesięcy.
          </p>
          <p>
            Sprzedaż obligacji przed terminem nie wiąże się z czasochłonnymi
            procesami. Podobnie w przypadku sprzedaży akcji na giełdzie.
            Pieniądze na koncie inwestora powinny pojawić się w ciągu tygodnia –
            obligacje, oraz w ciągu 2 dni roboczych – giełda. Należy jednak
            pamiętać, że przedterminowa sprzedaż obligacji może się wiązać z
            utratą odsetek.
          </p>
          <small>1. Dział analiz Five Seasons</small>
        </div>
      </section>

      <GlobalContact />
      <GlobalGuide />
    </Layout>
  )
}

export default DlaczegoCondo
