import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"

import InvestmentTile from "components/InvestmentTile"

const Projects = ({ acf, investments }) => {
  const items = acf?.investmentsContent
    ? [...acf?.investmentsContent?.map(item => item.id)]
    : null

  return (
    acf?.investmentsContent && (
      <section className="invest-projects">
        <div className="container-fluid">
          <h2>Inne projekty z naszego portfolio</h2>
          <div className="row justify-content-center">
            {investments?.edges
              ?.filter(item => items.includes(item.node.id))
              ?.map((item, index) => (
                <div className="col-xl-4 col-md-6" key={index}>
                  <Fade direction={"top"} triggerOnce>
                    <InvestmentTile data={item} key={index} />
                  </Fade>
                </div>
              ))}
          </div>
        </div>
      </section>
    )
  )
}

export default Projects
