import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

import {
  Hero,
  Rows,
  Grid,
  Icons,
  Location,
  Attracts,
  Timeline,
  Calc,
  Contact,
  Investors,
  Ratings,
  Chart,
  Projects,
  Gallery,
  Video,
} from "page_components/investment"

const Post = ({ pageContext, data }) => {
  const fullSchema = JSON.parse(pageContext.data.seo.schema.raw)
  const acfData = data.allWpInvestment.nodes[0].acfInvestments

  return (
    <Layout
      seo={{
        title: pageContext.data.seo.title
          ? pageContext.data.seo.title
          : pageContext.data.title,
        description:
          pageContext.data.seo.metaDesc && pageContext.data.seo.metaDesc,
      }}
      isInvest
    >
      <Hero acf={acfData} data={data} />
      <Rows acf={acfData} />
      <Timeline acf={acfData} />
      <Calc acf={acfData} />
      <Contact acf={acfData} />
      <Grid acf={acfData} />
      <Icons acf={acfData} />
      <Location acf={acfData} />
      <Attracts acf={acfData} />
      <Investors acf={acfData} />
      <Ratings acf={acfData} />
      <Chart acf={acfData} />
      <Projects acf={acfData} investments={data?.allInvestment} />
      <Video acf={acfData} />
      <Gallery acf={acfData} />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    allWpInvestment(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        uri
        slug
        acfInvestments {
          investmentGallery {
            mediaItemUrl
          }
          investmentApartments {
            nazwaApartamentu
            rzutApartamentu {
              sourceUrl
            }
            liczbaPokoi
            powierzchniaPokoju
            cenaApartamentuNetto
            cenaApartamentuBrutto
            rocznyPrzychodBezPobytuWlascicielskiego
            rocznyPrzychodZPobytemWlascicielskim
            rocznaStopaZwrotuBezPobytuWlascicielskiego
            rocznaStopaZwrotuZPobytemWlascicielskim
            wyplataMiesiecznaDlaInwestoraBezPobytuWlascicielskiego
            wyplataMiesiecznaDlaInwestoraZPobytemWlascicielskim
          }
          investmentFiltrStatus
          investmentLocationCity
          investmentBiznesModel
          investmentFinishDate
          investmentLogo {
            localFile {
              publicURL
            }
          }
          investmentHeroBackground {
            localFile {
              publicURL
            }
          }
          investmentHeroVideo {
            localFile {
              publicURL
            }
          }
          rowsHeader
          rowsContent {
            header
            description
            buttonText
            buttonScrollHash
          }
          rowsGallery {
            localFile {
              publicURL
            }
          }
          rowsGallerySecond {
            localFile {
              publicURL
            }
          }
          gridContent {
            header
            description
            gallery {
              localFile {
                publicURL
              }
            }
          }
          iconsContent {
            icon {
              localFile {
                publicURL
              }
            }
            header
            description
          }
          locationBackground {
            localFile {
              publicURL
            }
          }
          locationMapImage {
            localFile {
              publicURL
            }
          }
          locationDescription
          locationBottomDescription
          attractionsSummer {
            image {
              localFile {
                publicURL
              }
            }
            header
          }
          attractionsWinter {
            image {
              localFile {
                publicURL
              }
            }
            header
          }
          timelineDates {
            number
            description
          }
          investorsContent {
            icon {
              localFile {
                publicURL
              }
            }
            header
            description
          }
          chartImage {
            localFile {
              publicURL
            }
          }
          chartDescription
          investmentGallery {
            localFile {
              publicURL
            }
          }
          video
          videoEmbedUrl
          investmentsContent {
            ... on WpInvestment {
              id
            }
          }
        }
      }
    }
    allInvestment: allWpInvestment(sort: { fields: date, order: DESC }) {
      edges {
        node {
          uri
          id
          slug
          title
          acfInvestments {
            investmentFiltrStatus
            investmentFiltrMarket
            investmentFiltrProfit
            investmentGallery {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default Post
