import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import GlobalGuide from "../components/globalGuide"

const NaszZespol = ({ data }) => {
  return (
    <Layout
      seo={{
        title: "Nasz Zespół",
      }}
    >
      <section className="subpage-top">
        <h1>Nasz Zespół</h1>
      </section>

      <section className="team-content">
        <div className="container-fluid">
          <div className="row">
            {data?.ourTeam?.nodes[0].acfTeam.ourTeamPersons.map(
              (node, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    className="team-content__tile"
                    style={{
                      backgroundImage: `url(${
                        node.personImage ? node.personImage.sourceUrl : ""
                      })`,
                    }}
                  >
                    <div className="team-content__tile-inner">
                      <h3>{node.personName}</h3>
                      <strong>{node.personPosition}</strong>
                      <p>{node.personDescription}</p>
                      <div className="team-content__tile-social">
                        {node.personLinkFb && (
                          <a href={node.personLinkFb} tager="_blank">
                            <img
                              src={
                                require("../assets/images/ico-fb.svg").default
                              }
                              alt=""
                            />
                          </a>
                        )}
                        {node.personLinkInst && (
                          <a href={node.personLinkInst} tager="_blank">
                            <img
                              src={
                                require("../assets/images/ico-tw.svg").default
                              }
                              alt=""
                            />
                          </a>
                        )}
                        {node.personLinkTw && (
                          <a href={node.personLinkTw} tager="_blank">
                            <img
                              src={
                                require("../assets/images/ico-inst.svg").default
                              }
                              alt=""
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </section>

      <GlobalGuide />
    </Layout>
  )
}

export const query = graphql`
  query {
    ourTeam: allWpPage(filter: { id: { eq: "cG9zdDoxNA==" } }) {
      nodes {
        acfTeam {
          ourTeamPersons {
            personImage {
              sourceUrl
            }
            personName
            personPosition
            personDescription
            personLinkFb
            personLinkInst
            personLinkTw
          }
        }
      }
    }
  }
`

export default NaszZespol
