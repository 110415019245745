import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import GlobalGuide from "../components/globalGuide"
import GlobalContact from "../components/globalContact"

const Post = ({ className, node, link, arrow, subString }) => (
  <Link to={`/blog/${node.slug}/`} className={className}>
    <div
      className="knowledge-content__tile-bg"
      style={{
        backgroundImage: `url(${
          node.featuredImage ? node.featuredImage.node.sourceUrl : ""
        })`,
      }}
    ></div>
    <div className="knowledge-content__tile-inner">
      <h3>{node.title}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: node.excerpt.substring(0, subString),
        }}
      />
      <span className="link--text">Czytaj więcej</span>
    </div>
    {arrow && <span className="link"></span>}
  </Link>
)

const BazaWiedzy = ({ data, newestPosts, archivePosts }) => {
  const posts = data.newestPosts.edges

  return (
    <Layout
      seo={{
        title: "Blog",
      }}
    >
      <section className="subpage-top">
        <h1>Blog</h1>
      </section>

      <section className="knowledge-content">
        <div className="container-fluid container-fluid--custom">
          <div className="row">
            <div className="col-md-4">
              {posts[0] && (
                <Post
                  {...posts[0]}
                  subString={150}
                  link
                  className="knowledge-content__tile knowledge-content__tile--dark"
                />
              )}
            </div>
            <div className="col-md-4">
              {posts[1] && (
                <Post
                  {...posts[1]}
                  subString={90}
                  arrow
                  className="knowledge-content__tile knowledge-content__tile--small"
                />
              )}
              {posts[2] && (
                <Post
                  {...posts[2]}
                  subString={90}
                  arrow
                  className="knowledge-content__tile knowledge-content__tile--small"
                />
              )}
            </div>
            <div className="col-md-4">
              {posts[3] && (
                <Post
                  {...posts[3]}
                  subString={150}
                  arrow
                  className="knowledge-content__tile"
                />
              )}
            </div>
          </div>
          <div className="row">
            {data.archivePosts.nodes &&
              data.archivePosts.nodes.map((node, index) => (
                <div className="col-lg-4" key={index}>
                  <Link
                    to={`/blog/${node.slug}/`}
                    className="knowledge-content__tile knowledge-content__tile--small"
                  >
                    <div
                      className="knowledge-content__tile-bg"
                      style={{
                        backgroundImage: `url(${
                          node.featuredImage
                            ? node.featuredImage.node.sourceUrl
                            : ""
                        })`,
                      }}
                    ></div>
                    <div className="knowledge-content__tile-inner">
                      <h3>{node.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt.substring(0, 90),
                        }}
                      />
                    </div>
                    <span className="link--text">Czytaj więcej</span>
                    <span className="link"></span>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>
      <GlobalContact />
      <GlobalGuide />
    </Layout>
  )
}

export const query = graphql`
  query {
    newestPosts: allWpPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          excerpt
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
    archivePosts: allWpPost(skip: 4, sort: { order: DESC, fields: date }) {
      nodes {
        title
        excerpt
        slug
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`

export default BazaWiedzy
