import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import GlobalContact from "../components/globalContact"

const Post = ({ pageContext }) => {
  const { title, content, latestPosts, date, data } = pageContext
  const fullSchema = JSON.parse(data.seo.schema.raw)

  return (
    <Layout
      seo={{
        title: data.seo.opengraphTitle ? data.seo.opengraphTitle : title,
        description: data.seo.opengraphDescription
          ? data.seo.opengraphDescription
          : data.seo.metaDesc,
        opengraphImage: data.seo && data.seo.opengraphImage.sourceUrl,
        opengraphType: data.seo && data.seo.opengraphType,
        opengraphUrl: `https://5s.com.pl/blog${
          data.seo && data.seo.opengraphUrl
        }`,
      }}
      light
    >
      <section className="subpage-top">
        <h1>{title}</h1>
      </section>

      <section className="post-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <div className="post-content__info">
                <img
                  src={require("../assets/images/ico-home.svg").default}
                  alt=""
                />
                <div className="post-content__breadcrumb">
                  <Link to="/">Strona główna</Link>
                  <span>></span>
                  <Link to="/blog/">Blog</Link>
                </div>
                <img
                  src={require("../assets/images/ico-user.svg").default}
                  alt=""
                />
                <p>
                  {data.author.node.firstName} {data.author.node.lastName}
                </p>
                <img
                  src={require("../assets/images/ico-calendar.svg").default}
                  alt=""
                />
                <time>{date.split("T")[0].split("-").reverse().join(".")}</time>
              </div>
              <div className="post-content__image">
                {data.featuredImage && (
                  <img
                    className="img-fluid"
                    src={data.featuredImage.node.localFile.publicURL}
                    alt=""
                  />
                )}
              </div>
              <div className="post-content__inner">
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </div>

            <div className="col-lg-3 offset-lg-1">
              <div className="post-content__sidebar">
                <h4>Najnowsze</h4>
                <ul>
                  {latestPosts.nodes.map((node, index) => (
                    <li key={index}>
                      <Link to={`/blog/${node.slug}/`}>{node.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GlobalContact />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Post
