import React from "react"

import Layout from "../components/layout"
import GlobalGuide from "../components/globalGuide"

const CookiePolicy = () => (
  <Layout
    seo={{
      title: "Polityka plików cookies",
    }}
  >
    <section className="subpage-top">
      <h1>Polityka plików cookies</h1>
    </section>

    <section className="default-content">
      <div className="container">
        <p>
          Niniejsza Polityka dotycząca Ciasteczek odnosi się do strony
          fiveseasons.pl Przez używanie serwisu fiveseasons.pl wyrażasz zgodę na
          używanie ciasteczek zgodnie z tą Polityką Ciasteczek. Jeżeli nie
          zgadzasz się na używanie przez nas ciasteczek, powinieneś zmienić
          ustawienia swojej przeglądarki w odpowiedni sposób lub zrezygnować z
          używania strony fiveseasons.pl.
        </p>
        <h4>Co to są ciasteczka?</h4>
        <p>
          Ciasteczka (ang. cookies) to niewielkie pliki, zapisywane i
          przechowywane na twoim komputerze, tablecie lub smartphonie podczas
          gdy odwiedzasz różne strony w internecie. Ciasteczko zazwyczaj zawiera
          nazwę strony internetowej, z której pochodzi, „długość życia”
          ciasteczka (to znaczy czas jego istnienia), oraz przypadkowo
          wygenerowany unikalny numer służący do identyfikacji przeglądarki, z
          jakiej następuje połączenie ze stroną internetową.
        </p>
        <h4>Do czego używamy ciasteczek?</h4>
        <p>
          fiveseasons.pl używa ciasteczek w różnych celach: by serwis działał
          szybciej i był łatwiejszy w użyciu, do zbierania anonimowych,
          zagregowanych statystyk, które pozwalają nam zrozumieć jak ludzie
          używają naszych stron i pomagają w poprawianiu ich funkcjonalności i
          zawartości. Używając ciasteczek w wyżej opisany sposób nigdy nie
          identyfikujemy tożsamości użytkowników na podstawie informacji
          przechowywanych w ciasteczkach.
        </p>
        <h4>Jak długo przechowywane są dane w ciasteczkach?</h4>
        <p>
          Na stronie fiveseasons.pl mogą być używane dwa rodzaje ciasteczek –
          sesyjne oraz stałe. Te pierwsze pozostają na Twoim urządzeniu jedynie
          podczas korzystania ze strony fiveseasons.pl. Ciasteczka stałe
          pozostają na Twoim urządzeniu tak długo jak długo mają ustawiony czas
          życia lub do momentu kiedy je usuniesz.
        </p>
        <h4>Rodzaje ciasteczek używanych na stronie fiveseasons.pl</h4>
        <p>
          Konieczne do działania stron – Niezbędne do prawidłowego
          funkcjonowania strony fiveseasons.pl, pozwalają Ci na poruszanie się
          po nich oraz używanie ich elementów. Przykładowo mogą zapamiętywać
          poprzednie czynności (np. otwarte teksty) podczas wracania na stronę w
          tej samej sesji.
        </p>
        <p>
          Poprawiające wydajność – Zbieranie informacji o tym jak odwiedzający
          korzystają ze strony fiveseasons.pl poprzez dostarczanie informacji na
          temat obszarów które odwiedzają, czasu jaki na nich spędzają oraz
          problemów jakie na nich napotykają, jak np. komunikaty o błędach. To
          pozwala nam poprawiać działanie strony fiveseasons.pl.
        </p>
        <h4>Czy używamy ciasteczek podmiotów trzecich?</h4>
        <p>
          Tak, korzystając z serwisu fiveseasons.pl możesz otrzymywać ciasteczka
          pochodzące od współpracujących z fiveseasons.pl podmiotów trzecich
          takich jak np. Facebook czy Google. Więcej informacji na temat tych
          ciasteczek możesz znaleźć na stronach internetowych poszczególnych
          podmiotów trzecich.
        </p>
        <h4>
          W jaki sposób mogę zmienić ustawienia dot. ciasteczek albo je usunąć?
        </h4>
        <p>
          Większość przeglądarek internetowych jest początkowo ustawionych na
          automatyczne przyjmowanie ciasteczek. Możesz jednak zmienić ustawienia
          przeglądarki tak, aby ciasteczka były blokowane – w całości lub w
          jakiejś części, np. tylko od stron trzecich, albo aby każdorazowo
          otrzymywać komunikat w momencie kiedy ciasteczka są wysyłane na Twoje
          urządzenie. Pamiętaj jednak, że jeżeli zablokujesz używane przez nas
          ciasteczka, może to negatywnie wpłynąć na wygodę korzystania ze strony
          fiveseasons.pl, na przykład możesz nie być w stanie odwiedzić pewnych
          obszarów strony fiveseasons.pl bądź nie otrzymywać spersonalizowanych
          informacji podczas ich przeglądania. Uniemożliwisz nam także zbieranie
          anonimowych informacji nt. używania naszych stron w celu stałego
          poprawiania zawartości strony fiveseasons.pl.
        </p>
        <h4>Cookies na stronie 5s.com.pl</h4>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Podmiot</th>
                <th>Nazwa cookie</th>
                <th>Cel stosowania</th>
                <th>Rodzaj cookie</th>
                <th width="20%">Czas przechowywania (termin usunięcia)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>5s.com.pl</td>
                <td>_ga</td>
                <td width="40%">
                  Umożliwienie Spółce śledzenia Użytkownika i jego korzystania z
                  Portalu. Jest ustawiany przy pierwszej wizycie w Portalu i
                  aktualizowany przy kolejnych wizytach. Gromadzi dane o: płci,
                  wieku, lokalizacji, przeglądanych podstronach, czasu
                  spędzonego na stronie, interakcji na stronie, rodzaju
                  urządzenia oraz przeglądarki
                </td>
                <td>Analityczny, stały</td>
                <td>2 lata</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <GlobalGuide />
  </Layout>
)

export default CookiePolicy
