import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout
    seo={{
      title: "404",
    }}
  >
    <section className="page-404">
      <div>
        <h2>404</h2>
        <div>
          <img src={require("../assets/images/ico-logo.svg").default} alt="" />
        </div>
        <Link to="/" className="btn-custom btn-custom--white">
          Powrót do strony głównej
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
