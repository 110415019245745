import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import GlobalGuide from "../components/globalGuide"

const PrivacyPolicy = ({ data }) => {
  return (
    <Layout
      seo={{
        title: "Polityka prywatności",
      }}
    >
      <section className="subpage-top">
        <h1>Polityka prywatności</h1>
      </section>

      <section className="default-content">
        <div
          className="container"
          dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
        />
      </section>

      <GlobalGuide />
    </Layout>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxOTM1" }) {
      content
    }
  }
`
