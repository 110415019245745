import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"

const Ratings = () => {
  return (
    <section className="invest-ratings">
      <div className="container-fluid">
        <h3>Wyróżnienia</h3>
        <div className="row align-items-center">
          <div className="col-md-4">
            <Fade direction={"top"} triggerOnce>
              <h4>
                Bardzo wysokie oceny <br /> i Nagrody Operatora:
              </h4>
            </Fade>
          </div>
          <div className="col-md-8">
            <Fade direction={"top"} triggerOnce delay={250}>
              <div className="row">
                <div className="col-sm-3 col-6">
                  <div className="invest-ratings__tile">
                    <div className="invest-ratings__tile-thumb">
                      <img
                        src={
                          require("assets/images/logo-trip-advisor.png").default
                        }
                        alt=""
                      />
                    </div>
                    <span>4,1/5</span>
                  </div>
                </div>
                <div className="col-sm-3 col-6">
                  <div className="invest-ratings__tile">
                    <div className="invest-ratings__tile-thumb">
                      <img
                        src={
                          require("assets/images/logo-holiday-check.png")
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <span>4,4/6</span>
                  </div>
                </div>
                <div className="col-sm-3 col-6">
                  <div className="invest-ratings__tile">
                    <div className="invest-ratings__tile-thumb">
                      <img
                        src={require("assets/images/logo-google.png").default}
                        alt=""
                      />
                    </div>
                    <span>4,6/5</span>
                  </div>
                </div>
                <div className="col-sm-3 col-6">
                  <div className="invest-ratings__tile">
                    <div className="invest-ratings__tile-thumb">
                      <img
                        src={require("assets/images/logo-booking.png").default}
                        alt=""
                      />
                    </div>
                    <span>8,9/10</span>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Ratings
