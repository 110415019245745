import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const InvestmentTile = ({ data }) => {
  return (
    <Link to={`/nasze-inwestycje${data.node.uri}`} className="investment-tile">
      <div
        className="investment-tile__image"
        style={{
          backgroundImage: `url(${data?.node?.acfInvestments?.investmentGallery[0]?.localFile?.publicURL})`,
        }}
      ></div>
      <h3>{data.node.title}</h3>
    </Link>
  )
}

export default InvestmentTile
