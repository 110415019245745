import "./styles.scss"

import React from "react"

const Timeline = ({ acf }) => {
  return (
    acf?.timelineDates && (
      <section className="invest-timeline">
        <div className="container-fluid">
          <h3>Aktualny etap budowy</h3>
          <div className="invest-timeline__wrapper">
            {acf?.timelineDates?.map((item, index) => (
              <div className="invest-timeline__item" key={index}>
                <strong>{item?.number}</strong>
                <p>
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  )
}

export default Timeline
