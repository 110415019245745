import "./styles.scss"

import React from "react"
import Slider from "react-slick"
import { Fade } from "react-awesome-reveal"

const Grid = ({ acf }) => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
  }

  return (
    acf?.gridContent && (
      <section className="invest-grid" id="about">
        <div className="container-fluid">
          {acf?.gridContent?.map((item, index) =>
            !index % 2 ? (
              <div className="row" key={index}>
                <div className="col-lg-5 col-md-6">
                  <Fade direction={"top"} triggerOnce>
                    <div>
                      {item?.header && <h3>{item?.header}</h3>}
                      <div
                        dangerouslySetInnerHTML={{ __html: item?.description }}
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-6 offset-lg-1 col-md-6">
                  <Fade direction={"top"} triggerOnce delay={500}>
                    <Slider {...slider}>
                      {item?.gallery.map(item => (
                        <div key={item}>
                          <div
                            className="invest-rows__image"
                            style={{
                              backgroundImage: `url('${item?.localFile?.publicURL}')`,
                            }}
                          ></div>
                        </div>
                      ))}
                    </Slider>
                  </Fade>
                </div>
              </div>
            ) : (
              <div className="row" key={index}>
                <div className="col-md-6">
                  <Slider {...slider}>
                    {item?.gallery.map(item => (
                      <div key={item}>
                        <div
                          className="invest-rows__image"
                          style={{
                            backgroundImage: `url('${item?.localFile?.publicURL}')`,
                          }}
                        ></div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="col-md-6">
                  {item?.header && <h3>{item?.header}</h3>}
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </section>
    )
  )
}

export default Grid
