import "./styles.scss"

import React from "react"

const Video = ({ acf }) => {
  return (
    acf?.videoEmbedUrl && (
      <section className="invest-video">
        <div className="container-fluid">
          <h3>Obejrzyj film z inwestycji</h3>
          <div className="invest-video__video">
            <iframe
              width="100%"
              height="100%"
              src={acf?.videoEmbedUrl}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    )
  )
}

export default Video
