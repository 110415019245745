import "./styles.scss"

import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import { Fade } from "react-awesome-reveal"

const Gallery = ({ acf }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const gallery = acf?.investmentGallery?.map(item => item.localFile.publicURL)

  return (
    acf?.investmentGallery && (
      <section className="invest-gallery">
        <div className="container-fluid">
          <h3>Galeria</h3>
          <div className="row">
            {acf?.investmentGallery?.map((item, index) => (
              <div className="col-md-6" key={index}>
                <Fade direction={"top"} triggerOnce>
                  <a
                    className="invest-gallery__item"
                    style={{
                      backgroundImage: `url('${item?.localFile?.publicURL}')`,
                    }}
                    href="/"
                    onClick={e => {
                      e.preventDefault()
                      setIsLightboxOpen(true)
                      setPhotoIndex(index)
                    }}
                  >
                    Item
                  </a>
                </Fade>
              </div>
            ))}
          </div>
        </div>

        {isLightboxOpen && (
          <Lightbox
            mainSrc={gallery[photoIndex]}
            nextSrc={gallery[(photoIndex + 1) % gallery.length]}
            prevSrc={
              gallery[(photoIndex + gallery.length - 1) % gallery.length]
            }
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % gallery.length)
            }
          />
        )}
      </section>
    )
  )
}

export default Gallery
