/* eslint-disable jsx-a11y/media-has-caption */

import "./styles.scss"

import React from "react"

const Hero = ({ data }) => {
  return (
    <section
      className="invest-hero"
      style={{
        backgroundImage: `url('${data?.allWpInvestment?.nodes[0].acfInvestments?.investmentHeroBackground?.localFile?.publicURL}')`,
      }}
    >
      {data?.allWpInvestment?.nodes[0].acfInvestments?.investmentHeroVideo
        ?.localFile?.publicURL && (
        <div className="invest-hero__video">
          <video
            width="100%"
            height="100%"
            loop="true"
            autoplay="autoplay"
            controls="controls"
            muted
          >
            <source
              src={
                data?.allWpInvestment?.nodes[0].acfInvestments
                  ?.investmentHeroVideo?.localFile?.publicURL
              }
              type="video/mp4"
            />
          </video>
        </div>
      )}
      <div className="container-fluid">
        {data?.allWpInvestment?.nodes[0].acfInvestments?.investmentLogo
          ?.localFile?.publicURL && (
          <img
            src={
              data?.allWpInvestment?.nodes[0].acfInvestments?.investmentLogo
                ?.localFile?.publicURL
            }
            alt=""
          />
        )}
        <h3>{data?.allWpInvestment?.nodes[0].title}</h3>
        {data?.allWpInvestment?.nodes[0].acfInvestments
          ?.investmentLocationCity && (
          <h4>
            {
              data?.allWpInvestment?.nodes[0].acfInvestments
                ?.investmentLocationCity
            }
          </h4>
        )}
        <div className="invest-hero__items">
          {data?.allWpInvestment?.nodes[0].acfInvestments
            ?.investmentFiltrStatus && (
            <div className="invest-hero__item">
              <h5>Status:</h5>
              <p>
                {data?.allWpInvestment?.nodes[0].acfInvestments
                  ?.investmentFiltrStatus === "operate"
                  ? "Działające"
                  : "W budowie"}
              </p>
            </div>
          )}
          {data?.allWpInvestment?.nodes[0].acfInvestments
            ?.investmentBiznesModel && (
            <div className="invest-hero__item">
              <h5>Model biznesowy:</h5>
              <p>
                {
                  data?.allWpInvestment?.nodes[0].acfInvestments
                    ?.investmentBiznesModel
                }
              </p>
            </div>
          )}
          {data?.allWpInvestment?.nodes[0].acfInvestments
            ?.investmentFinishDate && (
            <div className="invest-hero__item">
              <h5>Data uruchomienia:</h5>
              <p>
                {
                  data?.allWpInvestment?.nodes[0].acfInvestments
                    ?.investmentFinishDate
                }
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Hero
