import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"

const Icons = ({ acf }) => {
  return (
    acf?.iconsContent && (
      <section className="invest-icons">
        <div className="container-fluid">
          <div className="row justify-content-center">
            {acf?.iconsContent?.map((item, index) => (
              <>
                <div className="col-lg-3 col-sm-6">
                  <Fade direction={"top"} triggerOnce>
                    <div className="invest-icons__item">
                      <div className="invest-icons__item-icon">
                        <img src={item?.icon?.localFile?.publicURL} alt="" />
                      </div>
                      {item?.header && <strong>{item?.header}</strong>}
                      {item?.description && <p>{item?.description}</p>}
                    </div>
                  </Fade>
                </div>
                {index === 2 && <div className="w-100"></div>}
              </>
            ))}
          </div>
        </div>
      </section>
    )
  )
}

export default Icons
