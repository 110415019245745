import "./styles.scss"

import React from "react"
import Slider from "react-slick"
import scrollToElement from "scroll-to-element"
import { Fade } from "react-awesome-reveal"

const Rows = ({ acf }) => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
  }

  return (
    acf?.rowsContent && (
      <section className="invest-rows">
        <div className="container-fluid">
          {acf?.rowsHeader && <h3>{acf?.rowsHeader}</h3>}
          <div className="row">
            <div className="col-lg-5 col-md-6">
              {acf?.rowsContent?.map((item, index) => (
                <Fade direction={"top"} triggerOnce key={index}>
                  <div className="invest-rows__item">
                    {item?.header && <h4>{item?.header}</h4>}
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                    {item.buttonText && (
                      <div className="invest-rows__item-cta">
                        <button
                          onClick={() => scrollToElement(item.buttonScrollHash)}
                          className="btn-custom btn-custom--arrow"
                        >
                          {item.buttonText}
                        </button>
                      </div>
                    )}
                  </div>
                </Fade>
              ))}
            </div>
            <div className="col-lg-6 offset-lg-1 col-md-6">
              <Fade direction={"top"} triggerOnce>
                <Slider {...slider}>
                  {acf?.rowsGallery?.map((item, index) => (
                    <div key={index}>
                      <div
                        className="invest-rows__image"
                        style={{
                          backgroundImage: `url('${item?.localFile?.publicURL}')`,
                        }}
                      ></div>
                    </div>
                  ))}
                </Slider>
              </Fade>

              <Fade direction={"top"} delay={500} triggerOnce>
                <Slider {...slider}>
                  {acf?.rowsGallerySecond?.map((item, index) => (
                    <div key={index}>
                      <div
                        className="invest-rows__image"
                        style={{
                          backgroundImage: `url('${item?.localFile?.publicURL}')`,
                        }}
                      ></div>
                    </div>
                  ))}
                </Slider>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    )
  )
}

export default Rows
