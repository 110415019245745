import React from "react"
import { graphql } from "gatsby"
import { Fade } from "react-awesome-reveal"

import Layout from "../components/layout"
import GlobalContact from "../components/globalContact"

const Onas = ({ data }) => {
  const acfData = data?.wpPage?.acfAbout

  return (
    <Layout
      seo={{
        title: "Doświadczony deweloper i operator hotelowy",
        description:
          "Jesteśmy doświadczonym deweloperem i operatorem hotelowym. Od ponad 10 lat budujemy i zarządzamy kompleksami hotelowymi zapewniając naszym inwestorom wysoki zwrot z inwestycji.",
      }}
    >
      <section className="subpage-top">
        <h1>O nas</h1>
      </section>

      <section className="about-content">
        <div className="container-fluid container-fluid--custom">
          {acfData.aboutContent.map((item, index) => (
            <div
              className={`row no-gutters align-items-center${
                index % 2 ? " row--odd" : ""
              }`}
              key={index}
            >
              <div className="col-md-6">
                <Fade delay={200} direction={"left"} triggerOnce>
                  <img
                    className="img-fluid"
                    src={item.aboutContentImage.localFile.publicURL}
                    alt=""
                  />
                </Fade>
              </div>
              <div className="col-md-6">
                <div className="about-content__inner">
                  <Fade delay={800} direction={"right"} triggerOnce>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.aboutContentDescription,
                      }}
                    />
                  </Fade>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <GlobalContact />
    </Layout>
  )
}

export default Onas

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxOTI3" }) {
      acfAbout {
        aboutContent {
          aboutContentDescription
          aboutContentImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
