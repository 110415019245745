import React from "react"

const HomeScope4 = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 396 366" version="1.1">
      <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <g id="Forma-1" transform="matrix(1,0,0,1,-1234.32,-4013.93)">
          <path d="M1324.83,4087.04L1302.46,4087.04L1302.46,4082.64L1324.68,4082.64C1326.31,4082.64 1327.65,4081.32 1327.65,4079.71L1327.65,4075.17C1327.65,4073.85 1326.76,4072.83 1325.42,4072.53C1325.13,4049.38 1306.46,4030.48 1283.2,4029.75L1283.2,4026.97L1289.13,4026.97C1290.02,4026.97 1290.61,4026.38 1290.61,4025.5L1290.61,4022.72C1290.61,4017.88 1286.61,4013.93 1281.72,4013.93C1276.84,4013.93 1272.84,4017.88 1272.84,4022.72L1272.84,4025.5C1272.84,4026.38 1273.43,4026.97 1274.32,4026.97L1280.24,4026.97L1280.24,4029.75C1256.98,4030.63 1238.32,4049.38 1238.17,4072.53C1236.84,4072.82 1235.8,4073.85 1235.8,4075.17L1235.8,4079.71C1235.8,4081.32 1237.28,4082.64 1238.91,4082.64L1260.98,4082.64L1260.98,4087.04L1238.76,4087.04C1236.24,4087.04 1234.32,4088.94 1234.32,4091.29L1234.32,4100.22C1234.32,4101.1 1234.91,4101.69 1235.8,4101.69L1327.65,4101.69C1328.54,4101.69 1329.13,4101.1 1329.13,4100.22L1329.13,4091.29C1329.13,4088.94 1327.2,4087.04 1324.83,4087.04ZM1275.8,4022.72C1275.8,4019.49 1278.46,4016.86 1281.72,4016.86C1284.98,4016.86 1287.65,4019.49 1287.65,4022.72L1287.65,4024.04L1275.8,4024.04L1275.8,4022.72ZM1238.76,4079.71L1238.76,4075.32L1309.87,4075.32C1310.76,4075.32 1311.35,4074.73 1311.35,4073.85C1311.35,4072.97 1310.76,4072.38 1309.87,4072.38L1241.13,4072.38C1241.43,4050.7 1259.21,4032.97 1281.13,4032.68C1281.28,4032.83 1281.58,4032.83 1281.72,4032.83C1281.87,4032.83 1282.17,4032.83 1282.32,4032.68C1304.39,4032.97 1322.17,4050.56 1322.46,4072.38L1320.24,4072.38C1319.35,4072.38 1318.76,4072.97 1318.76,4073.85C1318.76,4074.73 1319.35,4075.32 1320.24,4075.32L1324.68,4075.32L1324.68,4079.71L1238.76,4079.71ZM1263.95,4082.64L1299.5,4082.64L1299.5,4087.04L1263.95,4087.04L1263.95,4082.64ZM1326.17,4098.76L1237.28,4098.76L1237.28,4091.29C1237.28,4090.55 1237.87,4089.97 1238.76,4089.97L1324.83,4089.97C1325.57,4089.97 1326.17,4090.55 1326.17,4091.29L1326.17,4098.76Z" />
        </g>
      </g>
    </svg>
  )
}

export default HomeScope4
