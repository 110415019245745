import React from "react"

const HomeInfo4 = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 102 157" version="1.1">
      <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <g transform="matrix(1,0,0,1,-211.994,-4868.62)">
          <path d="M236.32,4899.88C236.32,4898.5 235.524,4897.2 234.018,4896.12C232.989,4895.39 231.686,4894.79 230.145,4894.34L229.588,4894.18L233.607,4888.19C235.356,4886.07 236.32,4883.4 236.32,4880.66C236.32,4874.02 230.863,4868.62 224.157,4868.62C217.451,4868.62 211.994,4874.02 211.994,4880.66C211.994,4882.95 212.655,4885.18 213.904,4887.1C213.977,4887.21 214.054,4887.33 214.134,4887.44L218.695,4894.19L218.137,4894.35C216.607,4894.8 215.312,4895.4 214.288,4896.13C212.787,4897.21 211.994,4898.5 211.994,4899.88C211.994,4901.66 213.326,4903.3 215.743,4904.49C218.002,4905.6 220.991,4906.21 224.157,4906.21C227.324,4906.21 230.312,4905.6 232.571,4904.49C234.988,4903.3 236.32,4901.66 236.32,4899.88ZM224.157,4904.81C217.738,4904.81 213.425,4902.26 213.425,4899.88C213.425,4898.09 215.711,4896.38 219.248,4895.52L219.541,4895.44L224.159,4902.28L228.746,4895.44L229.041,4895.51C232.593,4896.37 234.889,4898.09 234.889,4899.88C234.889,4902.26 230.577,4904.81 224.157,4904.81ZM215.108,4886.34L215.073,4886.29L215.074,4886.28C213.994,4884.6 213.425,4882.66 213.425,4880.66C213.425,4874.8 218.24,4870.03 224.157,4870.03C230.075,4870.03 234.889,4874.8 234.889,4880.66C234.889,4883.09 234.027,4885.47 232.463,4887.35L232.439,4887.37L224.155,4899.73L215.108,4886.34ZM231.398,4878.91L226.394,4878.19L224.157,4873.73L221.919,4878.19L216.916,4878.91L220.537,4882.38L219.682,4887.28L224.157,4884.97L228.632,4887.28L227.777,4882.38L231.398,4878.91ZM224.157,4883.38L221.582,4884.71L222.074,4881.89L219.991,4879.89L222.87,4879.48L224.157,4876.91L225.444,4879.48L228.323,4879.89L226.24,4881.89L226.732,4884.71L224.157,4883.38Z" />
        </g>
      </g>
    </svg>
  )
}

export default HomeInfo4
