import React from "react"

import Layout from "../components/layout"

const Catalogue = () => {
  return (
    <Layout
      seo={{
        title: "Katalog Five Seasons",
      }}
      rmvSocial
    >
      <section className="subpage-top">
        <h1>Katalog Five Seasons</h1>
      </section>

      <section className="page-catalogue">
        <div className="page-catalogue__wrapper">
          {/* <div className="page-catalogue__cta">
            <a
              className="btn-custom btn-custom--arrow"
              href={withPrefix("/5seasons_katalog_FINAL.pdf")}
              target="_blank"
              rel="noreferrer"
            >
              Otwórz katalog w nowej karcie
            </a>
          </div> */}

          <div className="embed-container-flipbook">
            <iframe
              src="https://online.fliphtml5.com/xgxql/ftqv/"
              seamless="seamless"
              frameBorder="0"
              allowtransparency="true"
              allowFullScreen="true"
              title="Katalog"
            ></iframe>
          </div>

          {/* <div class="embed-container">
            <object
              data={withPrefix("/5seasons_katalog_FINAL.pdf")}
              type="application/pdf"
              title="Katalog"
            ></object>
          </div> */}
        </div>
      </section>
    </Layout>
  )
}

export default Catalogue
