import React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"

const Availability = () => (
  <Layout
    seo={{
      title: "Dostępność pokoi",
    }}
  >
    <section className="page-404">
      <div>
        <h3>Nie znaleziono lokali</h3>
        <div>
          <img
            src={require("../../assets/images/ico-logo.svg").default}
            alt=""
          />
        </div>
        <Link to="/" className="btn-custom btn-custom--white">
          Powrót do strony głównej
        </Link>
      </div>
    </section>
  </Layout>
)

export default Availability
