import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"

const Investors = ({ acf }) => {
  return (
    acf?.investorsContent && (
      <section className="invest-investors">
        <div className="container-fluid">
          <h3>Dlaczego inwestorzy nas doceniają</h3>
          <div className="row justify-content-center">
            {acf?.investorsContent?.map((item, index) => (
              <div className="col-lg-3 col-sm-6" key={index}>
                <Fade direction={"top"} triggerOnce>
                  <div className="invest-investors__item">
                    <div className="invest-investors__item-icon">
                      <img src={item?.icon?.localFile?.publicURL} alt="" />
                    </div>
                    <strong>{item.header}</strong>
                    <p>{item.description}</p>
                  </div>
                </Fade>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  )
}

export default Investors
