/* eslint-disable */

import React, { useState } from "react"
import { StaticQuery, graphql, Link, navigate } from "gatsby"
import Slider from "react-slick"
import $ from "jquery"
import { Fade } from "react-awesome-reveal"

import Layout from "../components/layout"
import GlobalGuide from "../components/globalGuide"
import ContactForm from "../components/contactForm"
import InvestmentTile from "../components/InvestmentTile"

import HomeScope1 from "../components/svg/home-scope-1"
import HomeScope2 from "../components/svg/home-scope-2"
import HomeScope4 from "../components/svg/home-scope-4"
import HomeScope5 from "../components/svg/home-scope-5"
import HomeInfo1 from "../components/svg/home-info-1"
import HomeInfo2 from "../components/svg/home-info-2"
import HomeInfo3 from "../components/svg/home-info-3"
import HomeInfo4 from "../components/svg/home-info-4"
import HomeInfo5 from "../components/svg/home-info-5"

const IndexPage = ({ data }) => {
  const homeContent = data.homeAcf.edges[0].node.homePageAcf
  const [scope, setScope] = useState("1")
  const [info, setInfo] = useState("2")
  const [calculatorBookmark, setCalculatorBookmark] = useState("1")
  const [calculatorSelect, setCalculatorSelect] = useState("59%")
  const handleSelectChange = event => {
    setCalculatorSelect(event.target.value)
  }
  const homeSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
  }
  const realisationsSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
  }

  return (
    <Layout
      seo={{
        title: "Apartamenty inwestycyjne i condohotele na sprzedaż",
        description:
          "Zainwestuj w apartamenty inwestycyjne i condohotele w górach lub nad morzem m.in. Świeradów-Zdrój, Szklarska Poręba, Karpacz, Pobierowo. Stały przychód z najmu lub podział przychodu. Ochroń swój kapitał przed inflacją.",
      }}
      homeEffect={true}
    >
      <section className="home-top">
        <Slider {...homeSlider}>
          {homeContent.heroSliderNew.map((item, index) => {
            if (item.type === "video") {
              return (
                <div key={index}>
                  <div className="home-top__wrapper no-overalay">
                    <div
                      className="home-top__wrapper-video"
                      style={{ backgroundColor: "#000000" }}
                    >
                      <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        style={{ opacity: "0.5" }}
                      >
                        <source
                          src={item?.video?.localFile?.publicURL}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="home-top__wrapper-inner">
                      <img
                        src={
                          require("../assets/images/logo-small-white.svg")
                            .default
                        }
                        alt=""
                      />
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.desc,
                        }}
                      />
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                          item.buttonLink
                            ? navigate(item.buttonLink)
                            : $("html, body").animate(
                                {
                                  scrollTop: window.innerHeight,
                                },
                                1000
                              )
                        }}
                        className="btn-custom btn-custom--gold"
                      >
                        {item.buttonText
                          ? item.buttonText
                          : "Poznaj nasze możliwości"}
                      </a>
                    </div>
                  </div>
                </div>
              )
            } else if (item.type === "image") {
              return (
                <div key={index}>
                  <div
                    className="home-top__wrapper"
                    style={{
                      backgroundImage: `url(${
                        item.image ? item.image.sourceUrl : ""
                      })`,
                    }}
                  >
                    <div className="home-top__wrapper-inner">
                      <img
                        src={
                          require("../assets/images/logo-small-white.svg")
                            .default
                        }
                        alt=""
                      />
                      <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
                      <p dangerouslySetInnerHTML={{ __html: item.desc }} />
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                          item.buttonLink
                            ? navigate(item.buttonLink)
                            : $("html, body").animate(
                                {
                                  scrollTop: window.innerHeight,
                                },
                                1000
                              )
                        }}
                        className="btn-custom btn-custom--gold"
                      >
                        {item.buttonText
                          ? item.buttonText
                          : "Poznaj nasze możliwości"}
                      </a>
                    </div>
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}
          {/* {homeContent.heroSlider.map((item, index) => {
            return (
              <div key={index}>
                <div
                  className="home-top__wrapper"
                  style={{
                    backgroundImage: `url(${item.img.sourceUrl})`,
                  }}
                >
                  <div className="home-top__wrapper-inner">
                    <img
                      src={require("../assets/images/logo-small-white.svg").default}
                      alt=""
                    />
                    <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
                    <p dangerouslySetInnerHTML={{ __html: item.desc }} />
                    <a
                      href="/"
                      onClick={e => {
                        e.preventDefault()
                        $("html, body").animate(
                          {
                            scrollTop: window.innerHeight,
                          },
                          1000
                        )
                      }}
                      className="btn-custom btn-custom--gold"
                    >
                      Poznaj nasze możliwości
                    </a>
                  </div>
                </div>
              </div>
            )
          })} */}
        </Slider>
        <a
          href="/"
          onClick={e => {
            e.preventDefault()
            $("html, body").animate(
              {
                scrollTop: window.innerHeight,
              },
              1000
            )
          }}
          className="home-top__scroll"
        ></a>
      </section>

      <main>
        <span className="anchor" id="more"></span>
        <section className="home-realisations">
          <div className="container-fluid">
            <Slider {...realisationsSlider}>
              {data.allRealisations.nodes.map((node, index) => (
                <div key={index}>
                  <div
                    className="home-realisations__wrapper"
                    style={{
                      backgroundImage: `url(${
                        node.acfRealisations.realisationImage &&
                        node.acfRealisations.realisationImage.sourceUrl
                      })`,
                    }}
                  >
                    <div className="home-realisations__wrapper-inner">
                      <h3>Poznaj nasze realizacje</h3>
                      <div className="home-realisations__wrapper-row">
                        <div>
                          <img
                            src={
                              node.acfRealisations.realisationLogo &&
                              node.acfRealisations.realisationLogo.sourceUrl
                            }
                            alt={node.title}
                          />
                        </div>
                        <div>
                          <p>Wartość</p>
                          <strong>
                            {node.acfRealisations.realisationValue}
                          </strong>
                        </div>
                        <div>
                          <p>Ilość Apartamentów</p>
                          <strong>
                            {node.acfRealisations.realisationApartments}
                          </strong>
                        </div>
                        <div>
                          <p>PUM</p>
                          <strong>
                            {node.acfRealisations.realisationPum} m<sup>2</sup>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <Fade delay={150} direction={"top"}>
              <div className="home-realisations__description">
                <h3>Wszystkie nasze obiekty</h3>
                <div className="row">
                  <div className="col-sm-4">
                    <img
                      src={
                        require("../assets/images/home-video-ico-1.svg").default
                      }
                      alt=""
                    />
                    <strong>Wygenerowały</strong>
                    <p>
                      300 000 000 PLN wypłat dla inwestorów tytułem umowy najmu
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <img
                      src={
                        require("../assets/images/home-video-ico-2.svg").default
                      }
                      alt=""
                    />
                    <strong>65%</strong>
                    <p>To średnie obłożenie naszych obiektów</p>
                  </div>
                  <div className="col-sm-4">
                    <img
                      src={
                        require("../assets/images/home-video-ico-3.svg").default
                      }
                      alt=""
                    />
                    <strong>9</strong>
                    <p>wybudowanych obiektów hotelowych</p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </section>

        <section className="home-contact">
          <div className="container-fluid">
            <div className="home-contact__wrapper">
              <div className="home-contact__wrapper-inner">
                <Fade delay={150} direction={"left"} triggerOnce>
                  <h3>
                    Zostaw dane do <br /> przesłania pełnej oferty
                  </h3>
                </Fade>
                <Fade delay={300} direction={"left"} triggerOnce>
                  <div>
                    <ContactForm />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <section className="home-projects">
          <div className="container-fluid">
            <h2>Inwestycje w sprzedaży</h2>
            <div className="row justify-content-center">
              {data.allWpInvestment &&
                data.allWpInvestment?.edges
                  ?.filter(
                    item =>
                      item.node.acfInvestments.investmentFiltrStatus ===
                      "construction"
                  )
                  .slice(0, 3)
                  .map((item, index) => (
                    <div className="col-xl-4 col-md-6" key={index}>
                      <Fade direction={"top"} triggerOnce>
                        <InvestmentTile data={item} key={index} />
                      </Fade>
                    </div>
                  ))}
            </div>
          </div>
        </section>

        <section className="home-calculator">
          <div className="container-fluid">
            <div className="calculator">
              <Fade delay={150} direction={"left"} triggerOnce>
                <div className="calculator__switch-container">
                  <div
                    className={
                      calculatorBookmark === "1"
                        ? "calculator__switch calculator__switch--active"
                        : "calculator__switch"
                    }
                    onClick={e => setCalculatorBookmark("1")}
                  >
                    Stała stopa zwrotu
                  </div>
                  {/* <div
                    className={
                      calculatorBookmark === "2"
                        ? "calculator__switch calculator__switch--active"
                        : "calculator__switch"
                    }
                    onClick={e => setCalculatorBookmark("2")}
                  >
                    Podział Zysku
                  </div> */}
                </div>
                <div className="calculator__label-row">
                  <div className="calculator__label">Projekt</div>
                  <div className="calculator__label">Lokal</div>
                  {calculatorBookmark === "2" && (
                    <div className="calculator__label calculator__label--last">
                      <div className="select-wrapper">
                        <select
                          name="calculator-select"
                          id="calculator-select"
                          onChange={e => handleSelectChange(e)}
                          value={calculatorSelect}
                        >
                          <option value="35%">35% (GUS)</option>
                          <option value="59%">59% (prognozowany)</option>
                          <option value="70%">70% (wzrostowy)</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <Fade delay={200} direction={"bottom"} triggerOnce>
                  <div className="calculator__info-row">
                    <div className="calculator__info-cell">
                      <img
                        src={
                          require(calculatorBookmark === "1"
                            ? "../assets/images/logo-forest-park.png"
                            : "../assets/images/logo-5seasons-black.png")
                            .default
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="calculator__info-cell">
                      <div className="calculator__cell-label">
                        Powierzchnia lokalu:
                      </div>
                      {calculatorBookmark === "1" ? "33.61 m²" : "34.99 m²"}
                    </div>
                    <div className="calculator__info-cell">
                      <div className="calculator__cell-label">
                        Liczba pokoi:
                      </div>
                      {calculatorBookmark === "1" ? "2" : "2"}
                    </div>
                    <div className="calculator__info-cell">
                      <div className="calculator__cell-label">Cena netto:</div>
                      {calculatorBookmark === "1"
                        ? "700 047,79 zł"
                        : "520 411,22 zł"}
                      <div className="calculator__cell-small-info">
                        Cena brutto{" "}
                        <strong>
                          {calculatorBookmark === "1"
                            ? "861 058,78 zł"
                            : "640 105,80 zł"}
                        </strong>{" "}
                        zł <br />
                        podatek VAT można odzyskać
                      </div>
                    </div>
                    <div className="calculator__info-cell">
                      <div className="calculator__cell-label">
                        Przychód roczny:
                      </div>
                      {calculatorBookmark === "1"
                        ? "50 403,44 zł"
                        : calculatorSelect === "35%"
                        ? "50 816,00 zł"
                        : calculatorSelect === "59%"
                        ? "85 572,00 zł"
                        : "101 235,00 zł"}
                    </div>
                  </div>
                  <div className="calculator__bottom-row">
                    <div className="calculator__img-cell">
                      {calculatorBookmark === "1" ? (
                        <img
                          src={
                            require("../assets/images/calculator-a41.png")
                              .default
                          }
                          alt=""
                          className="img-fluid calculator__plan"
                        />
                      ) : (
                        <img
                          src={
                            require("../assets/images/zmienna-stopa.jpg")
                              .default
                          }
                          alt=""
                          className="img-fluid calculator__plan"
                        />
                      )}
                    </div>
                    <div className="calculator__hidden-row-container">
                      <div
                        className={
                          calculatorBookmark === "2"
                            ? "calculator__extra-row calculator__extra-row--active"
                            : "calculator__extra-row"
                        }
                      >
                        <div className="calculator__info-cell calculator__info-cell--extra">
                          <div className="calculator__cell-label calculator__cell-label--small">
                            Liczba dni obłożenia w skali roku
                          </div>
                          {calculatorSelect === "35%"
                            ? "128"
                            : calculatorSelect === "59%"
                            ? 216
                            : 255}
                        </div>
                        <div className="calculator__info-cell calculator__info-cell--extra">
                          <div className="calculator__cell-label calculator__cell-label--small">
                            Przychód z właściciela lokalu (50/50%)
                          </div>
                          {calculatorSelect === "35%"
                            ? "25 408,00 zł"
                            : calculatorSelect === "59%"
                            ? "42 876, 00 zł"
                            : "50 617,50 zł"}
                        </div>
                        <div className="calculator__info-cell calculator__info-cell--extra">
                          <div className="calculator__cell-label calculator__cell-label--small">
                            Dni do wykorzystania przez właściciela:
                          </div>
                          {calculatorSelect === "35%"
                            ? "237"
                            : calculatorSelect === "59%"
                            ? "149"
                            : "110"}
                        </div>
                      </div>
                      <div className="calculator__blue-cell">
                        <div className="calculator__cell-label calculator__cell-label--gold">
                          Co miesiąc wypłacimy Ci netto
                        </div>
                        {calculatorBookmark === "1"
                          ? "4 200,29 zł"
                          : calculatorBookmark === "2" &&
                            calculatorSelect === "35%"
                          ? "2 117,33 zł"
                          : calculatorBookmark === "2" &&
                            calculatorSelect === "59%"
                          ? "3 573,00 zł"
                          : "4 218,12 zł"}
                      </div>
                      <div className="calculator__blue-cell">
                        <div className="calculator__cell-label calculator__cell-label--gold">
                          Roczna stopa zwrotu
                        </div>
                        {calculatorBookmark === "1"
                          ? "7,20%"
                          : calculatorSelect === "35%"
                          ? "4,82%"
                          : calculatorSelect === "59%"
                          ? "8,24%"
                          : "9,73%"}
                      </div>
                    </div>
                  </div>
                </Fade>
              </Fade>
            </div>
            <div className="element-to-right">
              {" "}
              <Link
                to="/nasze-inwestycje"
                className={
                  calculatorBookmark === "1"
                    ? "btn-custom btn-custom--arrow btn-custom--std calculator__btn"
                    : "btn-custom btn-custom--arrow btn-custom--std calculator__btn calculator__btn--down"
                }
              >
                Nasze inwestycje
              </Link>
            </div>
          </div>
        </section>

        <section className="home-scope">
          <div className="container-fluid">
            <Fade delay={150} direction={"top"} triggerOnce>
              <h2>Czym sie zajmujemy?</h2>
            </Fade>
            <div className="row no-gutters">
              <div className="col-sm-3 col-6">
                <Fade delay={150} direction={"top"} triggerOnce>
                  <div
                    className={`home-scope__tile ${scope === "1" && "current"}`}
                    onClick={e => setScope("1")}
                  >
                    <HomeScope1 />
                    <p>Budowa</p>
                  </div>
                </Fade>
              </div>
              <div className="col-sm-3 col-6">
                <Fade delay={300} direction={"top"} triggerOnce>
                  <div
                    className={`home-scope__tile ${scope === "2" && "current"}`}
                    onClick={e => setScope("2")}
                  >
                    <HomeScope2 />
                    <p>Sprzedaż</p>
                  </div>
                </Fade>
              </div>
              <div className="col-sm-3 col-6">
                <Fade delay={450} direction={"top"} triggerOnce>
                  <div
                    className={`home-scope__tile ${scope === "3" && "current"}`}
                    onClick={e => setScope("3")}
                  >
                    <HomeScope4 />
                    <p>Pełna obsługa hotelowa</p>
                  </div>
                </Fade>
              </div>
              <div className="col-sm-3 col-6">
                <Fade delay={600} direction={"top"} triggerOnce>
                  <div
                    className={`home-scope__tile ${scope === "4" && "current"}`}
                    onClick={e => setScope("4")}
                  >
                    <HomeScope5 />
                    <p>Obsługa rynku wtórnego</p>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="home-scope__desc">
              <Fade delay={150} direction={"top"} triggerOnce>
                <div className="home-scope__desc-inner">
                  {scope === "1" ? (
                    <p>
                      <strong>
                        Naszą historię stanowi przeszło 1 000 000 000 zł w
                        przygotowanych i sprzedanych projektach
                      </strong>
                      . Każdy z zarządzanych przez nas projektów jest unikatowy.
                      Z pasją i w oparciu o wieloletnie doświadczenie
                      wyszukujemy najlepsze działki w najatrakcyjniejszych
                      rejonach Polski. Nasi eksperci skrupulatnie analizują
                      potencjał inwestycyjny danej lokalizacji, oceniają jej
                      prestiż oraz walory krajoznawczo-turystyczne. Efektem tego
                      są luksusowe 4-i 5-gwiazdkowe apartamenty ze średnią
                      obłożenia znacznie przewyższającą średnią krajową.
                    </p>
                  ) : scope === "2" ? (
                    <p>
                      <strong>
                        Z sukcesami realizujemy projekty na rynku nieruchomości
                        inwestycyjnych
                      </strong>
                      . Zajmujemy się kompleksową obsługą procesu sprzedaży
                      nieruchomości. Pomożemy Państwu w m.in.: uzyskaniu porady
                      prawnej, dotyczącej np. sukcesji majątkowej; obsłudze
                      księgowej; uzyskaniu informacji o możliwości odzyskania
                      podatku VAT. Wierzymy, że inwestor nie musi posiadać
                      specjalistycznej wiedzy dotyczącej rynku nieruchomości
                      inwestycyjnych – to nasze zadanie.
                    </p>
                  ) : scope === "3" ? (
                    <p>
                      <strong>
                        Eksperci branży hotelarskiej z wieloletnim stażem w
                        pełni zadbają, by inwestycja pracowała na Państwa zysk
                      </strong>
                      . Wszystkie apartamenty dostępne w naszej ofercie są w
                      pełni bezobsługowe. Oznacza to, że nie muszą Państwo
                      poświęcać cennego czasu, by uczestniczyć w rozbudowanym
                      procesie obsługi gości hotelu. Zarządzeniem, marketingiem
                      oraz innymi aspektami działania obiektu zajmie się
                      doświadczony zespół fachowców. O jego jakości mogą
                      świadczyć wysokie oceny w wiodących portalach takich jak
                      Booking.com czy TripAdvisor.com.
                    </p>
                  ) : (
                    <p>
                      <strong>
                        Pomożemy Państwu na każdym etapie rozwoju inwestycji
                      </strong>
                      . Kupując apartament z naszej oferty, nabywają Państwo
                      również pełne prawo własności do wybranej nieruchomości, a
                      także prawo własności gruntu wraz z wpisem do księgi
                      wieczystej. Oznacza to, że w dowolnym momencie, mogą się
                      Państwo zdecydować na sprzedaż apartamentu, a nasi
                      pracownicy pomogą w całym procesie – od znalezienia
                      kupców, po finalizację transakcji.
                    </p>
                  )}
                </div>
              </Fade>
            </div>
          </div>
        </section>

        <section className="home-info">
          <div className="container-fluid">
            <div className="home-info__wrapper">
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div
                    className={`home-info__wrapper-tab ${
                      info === "1" && "current"
                    }`}
                    onClick={e => setInfo("1")}
                  >
                    <Fade delay={150} direction={"left"} triggerOnce>
                      <div>
                        <HomeInfo1 />
                      </div>
                    </Fade>
                  </div>
                  <div
                    className={`home-info__wrapper-tab ${
                      info === "2" && "current"
                    }`}
                    onClick={e => setInfo("2")}
                  >
                    <Fade delay={300} direction={"left"} triggerOnce>
                      <div>
                        <HomeInfo2 />
                      </div>
                    </Fade>
                  </div>
                  <div
                    className={`home-info__wrapper-tab ${
                      info === "3" && "current"
                    }`}
                    onClick={e => setInfo("3")}
                  >
                    <Fade delay={450} direction={"left"} triggerOnce>
                      <div>
                        <HomeInfo3 />
                      </div>
                    </Fade>
                  </div>
                  <div
                    className={`home-info__wrapper-tab ${
                      info === "4" && "current"
                    }`}
                    onClick={e => setInfo("4")}
                  >
                    <Fade delay={600} direction={"left"} triggerOnce>
                      <div>
                        <HomeInfo4 />
                      </div>
                    </Fade>
                  </div>
                  <div
                    className={`home-info__wrapper-tab ${
                      info === "5" && "current"
                    }`}
                    onClick={e => setInfo("5")}
                  >
                    <Fade delay={750} direction={"left"} triggerOnce>
                      <div>
                        <HomeInfo5 />
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="col-9">
                  <Fade delay={150} direction={"top"} triggerOnce>
                    {info === "1" ? (
                      <div className="home-info__wrapper-inner">
                        <h3>Zaufanie</h3>
                        <p>
                          Posiadamy ponad 10-letnie doświadczenie w branży i
                          wybudowaliśmy wiele 4 i 5-gwiazdkowych hoteli, które
                          funkcjonują po dzień dzisiejszy.
                        </p>
                      </div>
                    ) : info === "2" ? (
                      <div className="home-info__wrapper-inner">
                        <h3>Misja i pasja</h3>
                        <p>
                          Sukcesy w realizowaniu różnorodnych projektów
                          inwestycyjnych nie byłyby możliwe, gdyby nie wiara i
                          olbrzymie zaangażowanie w naszą misję – pomaganie
                          klientom w bezpiecznym pomnażaniu pieniędzy. To, co
                          dla innych jest pracą, dla nas jest pasją.
                        </p>
                      </div>
                    ) : info === "3" ? (
                      <div className="home-info__wrapper-inner">
                        <h3>Indywidualne podejście</h3>
                        <p>
                          Od wyboru odpowiedniej działki pod budowę inwestycji,
                          przez doradztwo kredytowe, po kontakt z klientami
                          hotelu – do wszystkich zadań podchodzimy
                          indywidualnie, z dbałością o każdy szczegół. Wierzymy,
                          że tylko w taki sposób można oferować usługi na
                          najwyższym światowym poziomie.
                        </p>
                      </div>
                    ) : info === "4" ? (
                      <div className="home-info__wrapper-inner">
                        <h3>Lokalizacje premium</h3>
                        <p>
                          Miejsca, w których powstają nasze inwestycje należą do
                          najatrakcyjniejszych obszarów Polski. Duża baza
                          turystów, walory przyrodnicze i potencjał
                          inwestycyjny. To wszystko sprawia, że możemy
                          zaoferować Państwu jedną z najlepszych ofert na rynku.
                        </p>
                      </div>
                    ) : (
                      <div className="home-info__wrapper-inner">
                        <h3>Najwyższa jakość materiałów</h3>
                        <p>
                          Wierzymy, że długofalowego sukcesu nie da się osiągnąć
                          na skróty. Solidne materiały i elementy wykończeniowe
                          uznanych marek dają gwarancję, że po wielu latach
                          Państwa apartament nadal będzie wyglądał zjawiskowo.
                        </p>
                      </div>
                    )}
                  </Fade>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-md-8 offset-xl-6 offset-lg-5 offset-md-5">
                <Fade delay={150} direction={"right"} triggerOnce>
                  <img
                    className="home-info-img img-fluid"
                    src={
                      require("../assets/images/home-info-image-1.jpg").default
                    }
                    alt=""
                    style={{ width: "100%", objectFit: "cover" }}
                  />
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* <section className="home-why">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-3">
              <Fade delay={150} direction={"left"}>
              <Link
                to="/dlaczego-condo/"
                className="btn-custom btn-custom--std btn-custom--arrow"
              >
                Dlaczego Condo?
              </Link>
              </Fade>
            </div>
            <div className="col-md-2">
              <Fade delay={300} direction={"top"}>
              <div className="home-why__tile home-why__tile-1">
                <p>
                  +5% <br /> dla Condo
                </p>
              </div>
              </Fade>
            </div>
            <div className="col-md-2">
              <Fade delay={450} direction={"top"}>
              <div className="home-why__tile home-why__tile-2">
                <p>Lokata</p>
              </div>
              </Fade>
            </div>
            <div className="col-md-2">
              <Fade delay={600} direction={"top"}>
              <div className="home-why__tile home-why__tile-3">
                <p>Mieszkanie na wynajem</p>
              </div>
              </Fade>
            </div>
            <div className="col-md-2">
              <Fade delay={750} direction={"top"}>
              <div className="home-why__tile home-why__tile-4">
                <p>Lokata</p>
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </section> */}

      <GlobalGuide />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpInvestment: allWpInvestment(sort: { fields: date, order: DESC }) {
      edges {
        node {
          uri
          id
          slug
          title
          acfInvestments {
            investmentFiltrStatus
            investmentFiltrMarket
            investmentFiltrProfit
            investmentGallery {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allRealisations: allWpRealisation(sort: { fields: date, order: ASC }) {
      nodes {
        title
        acfRealisations {
          realisationApartments
          realisationPum
          realisationValue
          realisationImage {
            sourceUrl
          }
          realisationLogo {
            sourceUrl
          }
        }
      }
    }
    homeAcf: allWpPage(filter: { isFrontPage: { eq: true } }) {
      edges {
        node {
          homePageAcf {
            heroSliderNew {
              type
              title
              video {
                localFile {
                  publicURL
                }
              }
              desc
              buttonText
              buttonLink
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
