import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"

const Location = ({ acf }) => {
  return (
    acf?.locationBackground && (
      <section className="invest-location" id="location">
        <div
          className="invest-location__wrapper"
          style={{
            backgroundImage: `url('${acf?.locationBackground?.localFile?.publicURL}')`,
          }}
        >
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <Fade direction={"top"} triggerOnce>
                  <img
                    className="img-fluid"
                    src={acf?.locationMapImage?.localFile?.publicURL}
                    alt=""
                  />
                </Fade>
              </div>
              <div className="col-lg-5">
                <Fade direction={"top"} triggerOnce delay={250}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: acf?.locationDescription,
                    }}
                  />
                </Fade>
              </div>
            </div>
          </div>
        </div>

        {acf?.locationBottomDescription && (
          <div className="container-fluid">
            <Fade direction={"top"} triggerOnce>
              <div
                className="invest-location__description"
                dangerouslySetInnerHTML={{
                  __html: acf?.locationBottomDescription,
                }}
              />
            </Fade>
          </div>
        )}
      </section>
    )
  )
}

export default Location
